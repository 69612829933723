export enum MODAL_LIST {
  SELECT_TOKEN_IN = 'SELECT_TOKEN_IN',
  SELECT_TOKEN_OUT = 'SELECT_TOKEN_OUT',
  USER_SETTING = 'USER_SETTING',
  TRADE_ROUTE = 'TRADE_ROUTE',
  ACTIVITIES = 'ACTIVITIES',
  SELECT_PAGE = 'SELECT_PAGE',
}

export enum Page {
  SWAP = 'swap',
  SPIN = 'spin',
}